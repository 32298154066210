import "./index.css";
import "@fontsource/roboto/400.css";

import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-license-pro";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import i18n from "./i18n";
import store from "./js/redux/store";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./ts/components/ErrorBoundary";

LicenseInfo.setLicenseKey(
	"c4b02ecbc3f3b7f110add66acfc32fd0Tz04MjM1MyxFPTE3MzcwOTgwMDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

Sentry.init({
	dsn: "https://dede659462b84010859940b9f123b411@o932761.ingest.sentry.io/5881670",
	integrations: [new Integrations.BrowserTracing()],
	environment: `${process.env.REACT_APP_ENV ?? "err"}`,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<React.StrictMode>
		<CssBaseline />
		<Provider store={store}>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
